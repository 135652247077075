export default function CustomMaintenance() {
    return (
      <>
        <h1 style={{ fontSize: 'x-large', fontWeight: 'bold' }}>現在メンテナンス中です</h1>
        <div style={{ marginTop: '1em' }}>
          <p>システムアップデートのため現在一時的にサービスを停止しています。</p>
          <p>皆様にはご不便をおかけしますが、メンテナンス終了までしばらくお待ち下さい。</p>
        </div>
      </>
    )
}

